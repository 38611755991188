<template>
  <div>
    <WorkLogButton @on-close-work-log-form="refreshWorkLog" />
    <br />
    <WorkLogList :key="workLogListComponentKey" />
  </div>
</template>

<script>
import WorkLogList from "./components/WorkLogList";
import WorkLogButton from "./components/WorkLogButton";

export default {
  components: {
    WorkLogList,
    WorkLogButton,
  },
  data() {
    return {
      workLogListComponentKey: 0,
    };
  },
  methods: {
    refreshWorkLog() {
      this.workLogListComponentKey++;
    },
  },
};
</script>
