<template>
  <div>
    <b>Work Log List</b>
    <div v-if="worklogs.length > 0">
      <table border="solid">
        <tr class="header-table">
          <td class="center wd25">Date</td>
          <td class="center wd20">Worked (h)</td>
          <td class="center">Description</td>
          <td class="wd20"></td>
        </tr>
        <tr
          v-for="worklog in worklogs"
          :key="worklog.worklogID"
          class="no-line-height"
        >
          <td class="center">
            <span class="font1rem">{{ worklog.date }}</span>
          </td>
          <td class="center">{{ round2Decimal(worklog.time / 60) }}</td>
          <td>
            <b-tooltip :label="worklog.description" multilined position="is-bottom" size="is-large">
              {{ getDescriptionDisplayValue(worklog.description) }}
            </b-tooltip>
          </td>
          <td class="center">
            <b-button
              type="is-text"
              @click="editWorklog(worklog)"
              icon-left="pencil-outline"
            />
            <b-button
              type="is-text"
              @click="deleteWorkLog(worklog.worklogID, worklog.date)"
              icon-left="delete"
            />
          </td>
        </tr>
      </table>
    </div>
    <p v-else style="font-size: smaller">
      Empty
    </p>
  </div>
</template>

<script>
import { getWorkLogList, deleteWorkLog } from "@/api/ibiza";

export default {
  data() {
    return {
      jiraIssueKey: undefined,
      currentUserId: undefined,
      maxDescriptionTextLength: 50,
      worklogs: [],
    };
  },
  created() {
    this.onInit();
  },
  methods: {
    async onInit() {
      await this.getJiraInfo();
      if (this.jiraIssueKey) {
        await this.getWorkLogList();
      }
    },
    async getJiraInfo() {
      try {
        await this.getIssueInfo();
        await this.getCurrentUser();
      } catch (e) {
        console.log(e);
      }
    },
    async getIssueInfo() {
      const response = await AP.context.getContext();
      if (response.jira.issue) {
        this.jiraIssueKey = response.jira.issue.key;
      }
    },
    async getCurrentUser() {
      AP.user.getCurrentUser((user) => {
        if (user) {
          this.currentUserId = user.atlassianAccountId;
        }
      });
    },
    refreshWorkLogList() {
      this.getWorkLogList();
    },
    round2Decimal(num) {
      return Math.round(num * 100) / 100;
    },
    async getWorkLogList() {
      const response = await getWorkLogList(this.jiraIssueKey);
      if (response.data && response.data.data) {
        this.worklogs = response.data.data.filter(
          (w) => w.user_id === this.currentUserId
        );
      }
    },
    getDescriptionDisplayValue(description) {
      if (!description) return ''
      if (description.length > this.maxDescriptionTextLength) {
        return description.substring(0, this.maxDescriptionTextLength) + '...';
      }
      return description;
    },
    editWorklog(worklog) {
      const worklogForm = {
        key: "dialog-work-log",
        chrome: false,
        customData: worklog,
      };
      AP.dialog.create(worklogForm);
      AP.events.on("dialog.close", this.getWorkLogList);
    },
    async deleteWorkLog(worklogId, worklogDate) {
      this.$buefy.dialog.confirm({
        title: "Deleting work log",
        message: `Are you sure you want to delete work log on <b>${worklogDate}</b>?`,
        confirmText: "Delete",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.continueDeleteWorkLog(worklogId),
      });
    },
    async continueDeleteWorkLog(worklogId) {
      const response = await deleteWorkLog(worklogId);
      if (response.data.errorDescription) {
        this.createPopupError(response.data.errorDescription)
      } else {
        this.getWorkLogList();
      }
    },
    createPopupError(message) {
      this.$buefy.dialog.alert({
          title: 'Error',
          message: message,
          hasIcon: true,
          type: 'is-danger'
      });
    },
  },
};
</script>

<style scoped>
table {
  border-collapse: collapse;
  vertical-align: middle;
  width: 90%;
}

td,
th {
  border: 1px solid #dddddd;
  vertical-align: middle;
  text-align: left;
}

.header-table {
  line-height: 2.5rem;
  padding: 0.5rem;
}

.no-line-height {
  line-height: 1;
  padding: 0.2rem;
}

.center {
  text-align: center;
}

.font1rem {
  font-size: 1rem;
}

.wd25 {
  width: 25%;
}

.wd20 {
  width: 20%;
}

.wd10 {
  width: 10%;
}
</style>
