<template>
  <div>
    <b-button label="Log Work" type="is-info" @click="openWorkLogForm()" />
  </div>
</template>

<script>
export default {
  methods: {
    openWorkLogForm() {
      const worklogForm = {
        key: "dialog-work-log",
        chrome: false,
      };
      AP.dialog.create(worklogForm);
      AP.events.on("dialog.close", this.closeWorkLogForm);
    },
    closeWorkLogForm() {
      this.$emit('on-close-work-log-form');
    },
  },
};
</script>